'use client';

import { IconDotsVertical } from '@tabler/icons-react';
import { ListNotificationsResponseBody } from 'bff';
import { formatDistance } from 'date-fns';
import Link from 'next/link';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'ui';
import es from 'date-fns/locale/es';

type Notification = ListNotificationsResponseBody['items'][number];

export const NotificationList = ({
  items,
  onMarkAsRead,
  emptyStateMessage = 'No tienes notificaciones sin leer',
  onNotificationOptsChange,
}: {
  items: Notification[];
  onMarkAsRead: (notificationIds: number[]) => void;
  emptyStateMessage?: string;
  onNotificationOptsChange: (open: boolean) => void;
}) => {
  return (
    <div className='divide-y-[1px] w-full h-full'>
      {items?.length > 0 ? (
        items.map((notification) => {
          return (
            <div className='hover:bg-accent' key={notification.id}>
              <Link target='_blank' href={notification.href}>
                <div className='flex flex-row items-start justify-between'>
                  <div className='flex flex-col'>
                    <div className='flex flex-row items-start p-4 space-x-2'>
                      <Avatar
                        className='ring-[1px] ring-muted-foreground'
                        size='xs'
                      >
                        <AvatarImage src={notification.actor.profile_photo!} />
                        <AvatarFallback className='text-xs'>
                          {`${notification.actor.first_name.charAt(0)}${
                            notification.actor.last_name?.charAt(0) || ''
                          }`}
                        </AvatarFallback>
                      </Avatar>
                      <div className='flex flex-col'>
                        <ReactMarkdown
                          className='text-sm'
                          children={`**${notification.actor.first_name}${
                            notification.actor.last_name
                              ? ` ${notification.actor.last_name}`
                              : ''
                          }** ${notification.message}`}
                        />
                        <p className='text-xs text-muted-foreground'>
                          {formatDistance(
                            new Date(notification.created_at),
                            new Date(),
                            {
                              addSuffix: true,
                              locale: es,
                            },
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  {!notification.receivers[0].read && (
                    <DropdownMenu onOpenChange={onNotificationOptsChange}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          onClick={(e) => e.preventDefault()}
                          className='mt-2'
                          size='xs'
                          variant={'ghost'}
                        >
                          <IconDotsVertical className='w-4 h-4' />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                          onClick={() => onMarkAsRead([notification.id])}
                        >
                          Marcar como leído
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              </Link>
            </div>
          );
        })
      ) : (
        <div className='flex w-full h-full items-center justify-center'>
          <p className='text-sm text-muted-foreground'>{emptyStateMessage}</p>
        </div>
      )}
    </div>
  );
};
